
            @import "@/assets/sass/vars.portal.scss";
          





















































































































































































































































































































































































































































































































































.bg-inactive {
    background-color: $gray-200;
}

@media screen and (min-width: 1300px) {
    .table-responsive {
        overflow-x: auto !important;
    }
}
