
            @import "@/assets/sass/vars.portal.scss";
          










































































































































































































































































































































































.slider-control {
    margin-bottom: 5px;
}
.slider-col {
}

.slider-value {
    text-align: center;
    margin-bottom: 25px;
}
